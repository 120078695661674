import React from 'react';
import WebpImage from '../../WebpImage';
import { usePromo, promoStart, promoEnd } from "../../../utils/usePromo";
import './styles.scss';
import ProductTag from '../../ProductTag';

const ContactsCharacteristics = ({isAffiliatePage}) => {
  const isPromo = usePromo(promoStart, promoEnd)
  const lenses = [
    {
      img: "hubble.png", 
      title: "Hubble", 
      description: "Our classic daily lens — it’s affordable, convenient, and comfortable for all-day wear.",
      price: "<strike>$19.99/eye</strike> </br><span>$1</span> for your first order",
      type: "classic",
    },
    {
      img: isAffiliatePage ? "hydro-characteristics.png" : "hydro.png", 
      title: "Hydro by Hubble", 
      description: "With innovative packaging, Hydro by Hubble is naturally hydrating and has an incredibly smooth lens edge.",
      price: isAffiliatePage ? 
        "<strike>$24.99/eye</strike> <span>$9.99/eye</span> </br>60% off your first order"
        : "<strike>$24.99/eye</strike> <span>$9.99/eye</span> </br>60% off your first order",
      type: "hydro",
      labelText: "Best Value",
      tagText: "Popular Choice",
      tagColor: "purple"
    },
    {
      img: isAffiliatePage ? "skyhy-characteristics.png" : "skyhy.png", 
      title: "SkyHy by Hubble", 
      description: "As our most breathable lens, SkyHy by Hubble is made from silicone hydrogel, the material preferred by doctors.",
      price: isPromo ? 
        "<strike>$29.99/eye</strike> <span>$11.99/eye</span> </br>60% off your first order" :
        "<strike>$29.99/eye</strike> <span>$17.99/eye</span> </br>40% off your first order",
      type: "skyhy",
      tagText: "Premium Pick",
      tagColor: "orange"
    }
  ]
  const lenseList = isAffiliatePage ? lenses.filter(({description}) => !description.toLowerCase().includes('classic')) : lenses

  return (
    <section className={`characteristics-landing ${isAffiliatePage ? "affiliate" : ""}`}>
      <h2 className="characteristics-title">{`Our ${isAffiliatePage ? "Daily" : ""} Contact Lenses`}</h2>
      <div className="content-container">
        {lenseList.map(({ img, title, description, price, type, tagText, tagColor, labelText }) => {
          const isHydroSkyHy =
            title.includes("Hydro") || title.includes("SkyHy")
          return (
            <div className="item-block" data-type={type}>
              <div className='image-wrapper'>
                <WebpImage
                  wrapperClassName="image"
                  className="image"
                  fileName={`Pages/Intake/${img}`}
                />
                {(!isAffiliatePage && tagText) && <ProductTag text={tagText} color={tagColor} />}
              </div>

              <div className="text-block">
                <div>
                  <h2 className="lenses-title">{title}</h2>
                  <p className="description">{description}</p>
                </div>
                {isPromo && (
                  <p
                    className={
                      isHydroSkyHy
                        ? "limited-time-text"
                        : "limited-time-text d-none d-sm-block invisible"
                    }
                  >
                    Limited Time Only
                  </p>
                )}
                <div className='lenses-wrapper' data-type={type}>
                  {(!isAffiliatePage && labelText) && <ProductTag text={labelText} color='dark-purple' label />}
                  <p
                    className="lenses-price"
                    dangerouslySetInnerHTML={{ __html: price }}
                  ></p>
                </div>
              </div>
              {isAffiliatePage && (
                <a href="#order-section" className="start-button">
                  {`Shop ${
                    title.includes("Hydro")
                      ? "Hydro"
                      : title.includes("SkyHy")
                      ? "SkyHy"
                      : ""
                  } By Hubble`}
                </a>
              )}
            </div>
          )
        })}
      </div>
      {!isAffiliatePage && (
        <a href="#order-section" className="start-button">
          Start Your Subscription
        </a>
      )}
    </section>
  )
};

export default ContactsCharacteristics;
