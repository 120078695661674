import React from "react";
import Nav from "../components/Intake/Nav";
import AffiliateHero from "../components/Intake/AffiliateHero"
import AffiliateCallToAction from "../components/Intake/AffiliateCallToAction"
import AffiliateWhyHubble from "../components/Intake/AffiliateWhyHubble"
import AffiliatePress from "../components/Intake/AffiliatePress"
import OrderSection from "../components/Intake/OrderSection";
import "../styles/pages/intake-hydro-skyhy.scss"
import ContactsCharacteristics from "../components/Intake/ContactsCharacteristics";

const IntakePage = () => {
  return (
    <div className="hubble-content-hydro-skyhy affiliate">
      <Nav isAffiliatePage />
      <AffiliateHero />
      <AffiliateCallToAction />
      <ContactsCharacteristics isAffiliatePage />
      <AffiliateWhyHubble />
      <AffiliatePress />
      <OrderSection isHydroSkyhy isAffiliatePage />
    </div>
  )
}

export default IntakePage
