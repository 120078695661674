import React from 'react';
import WebpImage from '../../WebpImage';
import './styles.scss';

const AffiliateHero = () => {
  return (
    <section className="fb-hero-affiliate-hydro-skyhy">
      <div className='content-container'>
        <div className='text-block'>
          <div className='text-container'>
            <h1 className='hero-title'>Start Now for 60% Off</h1>
            <p className='hero-subtitle'>Save on your first order of Hydro or SkyHy by Hubble.</p>
            <a href='#order-section' className='start-button'>Start Your Subscription</a>
          </div>
        </div>
        <div className='image-block'>
          <WebpImage fileName={'Pages/Intake/fb-landing-affiliate-hero.png'} />
        </div>
      </div>
    </section>
  );
};

export default AffiliateHero;
