import React from "react"
import WebpImage from "../../WebpImage"
import "./styles.scss"

const AffiliateCallToAction = ({isIntake}) => {
  const img = "Pages/Intake/Hydro-Lens-Image.png"
  const intakeImage = "Pages/Intake/hubble-lens.png"

  return (
    <section className={`call-to-action-affiliate-hydro-skyhy ${isIntake ? "grid-section avg" : ""}`}>
      <div className="content-container">
        <div className="image-wrapper">
          {isIntake ? 
          <WebpImage
          wrapperClassName="cta-image"
          className="cta-image"
          fileName={intakeImage}
          /> :
          <WebpImage
              wrapperClassName="cta-image"
              className="cta-image"
              fileName={img}
          />
          }
        </div>
        <div className="copy-wrapper">
          <h3 className="cta-headline">Here’s How hubble Works</h3>
          <p className="cta-body">
            Signing up for Hubble's subscription service is quick and easy. Simply choose the contact lenses that best suit your needs, enter your prescription details, and check out. We'll handle the rest.
          </p>
          <ul className="cta-list">
            <li className="list-item"><WebpImage className="check-icon" fileName={'Pages/Intake/check-icon.png'}/> <p className="text">Choose from our affordable lenses</p></li>
            <li className="list-item"><WebpImage className="check-icon" fileName={'Pages/Intake/check-icon.png'}/> <p className="text">Your contacts auto-ship and deliver like clockwork</p></li>
            <li className="list-item"><WebpImage className="check-icon" fileName={'Pages/Intake/check-icon.png'}/> <p className="text">Personalize your subscription and control your delivery cadence</p></li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default AffiliateCallToAction
