import React, { useState, useRef, useEffect } from "react"
import WebpImage from "../../WebpImage"
import Slider from "react-slick"
import SliderPagination from "../../SliderPagination"
import { useWindowWidth } from "../../../services/hooks"
import "./styles.scss"

const Press = () => {
  const pressItems = [
    {
      body:
        "“Hubble is making contact lens ordering cool and accessible with its direct-to-consumer model. The first shipment of 15 pairs of classic Hubble lenses costs only $1, and thereafter they’re $20.”",
      image: "Pages/Intake/forbes.png",
      title: "forbes",
    },
    {
      body:
        "“Hubble is one of the most popular affordable contact lens startups. Unless you're tied to using a very specific brand, Hubble's daily contacts are a great way to save money.”",
      image: "Pages/Intake/Business-insider.png",
      title: "business-insider",
    },
    {
      body:
        "“Hubble offers disposable single vision daily contacts…which are made from high-grade methafilcon A hydrogel (translation: your eyes will thank you).”",
      image: "Pages/Intake/Rolling-Stone.png",
      title: "rolling-stone",
    },
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const [isMobile, setIsMobile] = useState()
  const sliderRef = useRef()
  const allPages = pressItems?.length
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 768)
  }, [windowWidth])


  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    beforeChange: (prev, next) => {
      const newCurrentPage = next > prev ? currentPage + 1 : currentPage - 1

      setCurrentPage(newCurrentPage || 1)
    },
  }

  return (
    <section className="fb-press-affiliate">
      <h2 className="press-title">Don't just take our word for it.</h2>
      {!isMobile ? (
        <div className="press-wrapper">
          {pressItems.map((item, index) => <PressItem {...item} key={index} />)}
        </div>
      ) : (
        <div className={`press-wrapper ${isMobile ? 'mobile' : '' }`}>
          <Slider
            {...settings}
            className="reviews-container-v3"
            ref={sliderRef}
            >
            {pressItems.map((item, index) => <PressItem {...item} key={index} />)}
          </Slider>
          <SliderPagination
            sliderRef={sliderRef}
            currentPage={currentPage}
            allPages={allPages}
            color={'blue'}
          />
        </div>
      )}
    </section>
  )
}

const PressItem = ({ body, title, image}) => {
  return (
    <div className="press-item">
      <p className="press-body">{body}</p>
      <WebpImage className={`press-image ${title}`} fileName={image} />
    </div>
  )
}

export default Press
