import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import "./style.scss"
import {hubbleClassic} from "../../../services/data";

const AffiliateWhyHubble = ({ isV3 }) => {
  return (
    <div className="why-hubble-affiliate grid-section">
      <h2 className="headline">Why Hubble?</h2>

      <div className="value-props-holder">
        <div className="value-prop">
          <StaticImage
            src="./assets/01-Hubble-Contacts-Start-for-$1.png"
            alt=""
          />
          <p className="value-title">{isV3 ? "Start for $1" : "New Customer Savings"}</p>
          <p className="value-copy">
            {isV3
              ? `Pay $1 for your first ${hubbleClassic.trialPeriodPairs} pairs of our classic lenses – or start SkyHy or Hydro by Hubble for a special price.`
              : "Get Hydro or SkyHy by Hubble for 40% off your first order."}
          </p>
        </div>

        <div className="value-prop">
          <StaticImage
            src="./assets/02-Hubble-Contacts-No-Hassle-Policies.png"
            alt=""
          />
          <p className="value-title">No-Hassle Policies</p>
          <p className="value-copy">Not seeing the value? Just send it back.</p>
        </div>

        <div className="value-prop">
          <StaticImage
            src="./assets/03-Hubble-Contacts-Cancel-Any-Time.png"
            alt="Cancel your subscription at any time"
          />
          <p className="value-title">Cancel at any Time</p>
          <p className="value-copy">
            Cancel your contact lens subscription at any time, for any reason.
          </p>
        </div>

        <div className="value-prop">
          <StaticImage
            src="./assets/04-Hubble-Contacts-Eye-Health.png"
            alt=""
          />
          <p className="value-title">Eye Health & Safety</p>
          <p className="value-copy">
            All Hubble lenses are FDA-approved, thoroughly inspected, and made with care.
          </p>
        </div>

        <div className="value-prop">
          <StaticImage
            src="./assets/05-Hubble-Contacts-Secure-Payment.png"
            alt=""
          />
          <p className="value-title">Secure Payment</p>
          <p className="value-copy">
            Safety first across all payment platforms.
          </p>
        </div>
      </div>
      {isV3 ? null : (
        <a href="#order-section" className="start-button">
          Start Your Subscription
        </a>
      )}
    </div>
  )
}

export default AffiliateWhyHubble
